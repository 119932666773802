import 'jquery';
import 'angular-gettext';
import 'corejs-typeahead';
import angular from 'angular';
import ClipboardJS from 'clipboard/dist/clipboard.min.js';

import AbstractAppController from 'gmf/controllers/AbstractAppController.js';

/**
 * @private
 */
class Common {
  /**
   * @param {AbstractAppController} controller .
   * @param {angular.IScope} $scope Scope.
   * @param {angular.auto.IInjectorService} $injector Main injector.
   */
  constructor(controller, $scope, $injector) {
    /** @type {angular.gettext.gettextCatalog} */
    const gettextCatalog = $injector.get('gettextCatalog');

    /**
     * @type {AbstractAppController}
     */
    this.controller_ = controller;

    // Expose the map so that we can easily debug
    window.map = this.controller_.map;

    const ngeoLocation = this.controller_.ngeoLocation;

    /**
     * Whether the portal was loaded from the 'inventaire' application.
     * @type {boolean}
     * @export
     */
    this.enableBackToInvApp = ngeoLocation.getParam('come_from_inventaire_app') !== undefined;

    // Synchronize lang to a dimension
    $scope.$watch(
      () => controller.lang,
      (lang) => {
        controller.dimensions['lang'] = lang;
      }
    );

    /**
     * For resolutions:
     * 305.7481130859375, 152.87405654296876, 76.43702827148438,
     * 38.21851413574219, 19.109257067871095, 9.554628533935547,
     * 4.777314266967774, 2.388657133483887, 1.1943285667419434,
     * 0.5971642833709717, 0.29858214168548586, 0.14929107084274293,
     * 0.07464553542137146, 0.03732276771068573, 0.018661383855342865
     *
     * Real scales: 1091955, 545978, 272989, 136494, 68247, 34123,
     * 17062, 8531, 4265, 2133, 1066, 1066, 533, 266, 133, 67.
     *
     * @type {!Array.<number>}
     * @export
     */
    //this.scaleSelectorValues = [1000000, 500000, 250000, 150000, 75000, 50000,
    //  20000, 10000, 5000, 2000, 1000, 500, 250, 150, 75];

    /**
     * @type {gmfx.GridMergeTabs}
     * @export
     */
    // this.gridMergeTabs = {
    //   'Locaux': ['enac_vacant', 'enac_realloue', 'ic_vacant', 'ic_realloue', 'sb_vacant', 'sb_realloue', 'sti_vacant', 'sti_realloue', 'sv_vacant',
    //     'sv_realloue', 'vp_colleges_vacant', 'vp_colleges_realloue', 'autres_vacant', 'autres_realloue']
    // };

    /**
     * @type {string|undefined}
     * @export
     */
    this.searchQuery;

    const searchKeys = ['q', 'room', 'sciper', 'person'];
    for (let i = 0, ii = searchKeys.length; i < ii; i++) {
      this.searchQuery = ngeoLocation.getParam(searchKeys[i]);
      if (this.searchQuery) {
        break;
      }
    }

    // Temporary workaround for https://jira.camptocamp.com/browse/GSEPF-306
    if (this.searchQuery === '=STCC 0 9303') {
      this.searchQuery = '=STCC 2 9428';
    }

    /**
     * The selected search feature.
     * @type {ol.Feature}
     * @export
     */
    this.searchSelected;

    /**
     * @type {boolean}
     * @export
     */
    this.routingInitialized;

    /**
     * @type {string|undefined}
     * @export
     */
    this.routingFrom = ngeoLocation.getParam('from');

    /**
     * @type {string|undefined}
     * @export
     */
    this.routingTo = ngeoLocation.getParam('to');

    if (this.routingFrom || (this.routingTo && this.controller_['toggleRightNavVisibility'])) {
      this.controller_['toggleRightNavVisibility']();
    }

    /**
     * The selected routing 'from' param.
     * @type {ol.Feature}
     * @export
     */
    this.routingFromSelected;

    // Synchronize 'from' query param
    $scope.$watch(
      () => this.routingInitialized && this.routingFromSelected,
      () => {
        if (this.routingInitialized) {
          if (this.routingFromSelected) {
            ngeoLocation.updateParams({
              from: this.routingFromSelected.get('label'),
            });
          } else {
            ngeoLocation.deleteParam('from');
          }
        }
      }
    );

    /**
     * The selected routing 'to' param.
     * @type {ol.Feature}
     * @export
     */
    this.routingToSelected;

    // Synchronize 'to' query param
    $scope.$watch(
      () => this.routingInitialized && this.routingToSelected,
      () => {
        if (this.routingInitialized) {
          if (this.routingToSelected) {
            ngeoLocation.updateParams({
              to: this.routingToSelected.get('label'),
            });
          } else {
            ngeoLocation.deleteParam('to');
          }
        }
      }
    );

    /**
     * @type {{line: ol.Feature, roadmap: Array.<ol.Feature>}|null}
     * @export
     */
    this.routingRoadmap;

    /**
     * @type {boolean}
     * @export
     */
    this.queryActive = true;

    // close the routing panel when a route is displayed
    // deactivate the query when a route is displayed
    $scope.$watch(
      () => this.routingRoadmap,
      () => {
        if (this.routingRoadmap) {
          this.controller_['toggleRightNavVisibility']();
        }
        this.queryActive = !this.routingRoadmap;
      }
    );

    // Adapt the map size to avoid stretching
    window.onresize = function () {
      // Necessary due to the 0.3s transition on the main element.
      const start = Date.now();
      let loop = true;
      const adjustSize = function () {
        controller.map.updateSize();
        controller.map.renderSync();
        if (loop) {
          window.requestAnimationFrame(adjustSize);
        }
        if (Date.now() - start > 300) {
          loop = false;
        }
      };
      adjustSize();
    };

    // Clipboard.js setup (for building query result)
    const clipboard = new ClipboardJS('.clipboard');
    const addTooltip = function (event, msg) {
      const trigger = jQuery(event.trigger);
      trigger.tooltip({
        title: msg,
        trigger: 'hover',
      });
      trigger.tooltip('show');
      // remove the tooltip on mouseleave
      trigger.one('mouseleave', () => {
        trigger.tooltip('dispose');
      });
    };
    clipboard.on('success', (event) => {
      addTooltip(event, gettextCatalog.getString('Link copied.'));
    });
    clipboard.on('error', (event) => {
      addTooltip(event, gettextCatalog.getString('Please, copy link manually.'));
    });

    // Clipboard.js setup (for point draw coordinate)
    const clipboardDraw = new ClipboardJS('.clipboard-coord');
    clipboardDraw.on('success', (event) => {
      addTooltip(event, gettextCatalog.getString('Coordinates copied.'));
    });
    clipboardDraw.on('error', (event) => {
      addTooltip(event, gettextCatalog.getString('Please, copy coordinates manually.'));
    });
  }

  /**
   * @export
   */
  openRoutingPanel() {
    // copy the selected search item to the routing destination input
    if (this.searchSelected) {
      this.routingTo = /** @type {string} */ (this.searchSelected.get('label'));
    }
    // go back to the root of the right menu
    const elements = jQuery('.gmf-mobile-nav-right a.gmf-mobile-nav-go-back');
    console.assert(elements.length === 1);
    this.controller_.timeout(() => {
      elements.get(0).click();
    });

    // open the right menu
    this.controller_['toggleRightNavVisibility']();
  }

  /**
   * @param {string} lang Language.
   * @export
   */
  switchLanguage(lang) {
    this.controller_.switchLanguage(lang);
  }

  /**
   * @param {string} lang Language.
   * @export
   */
  forceEnglishLang(lang) {
    this.controller_.lang = lang;
  }
}

Common.module = angular.module('epflCommon', [AbstractAppController.name]);

export default Common;
